import React from 'react'
import PropTypes from 'prop-types'
import Link from 'elements/Link/Link'
import Article from 'elements/Frame/Article'
import Title from 'elements/Typography/Title'
import Subtitle from 'elements/Typography/Subtitle'
import { Button } from '@chakra-ui/core'

export default function BlogPage ({ data }) {
  const {
    allMdx: { edges }
  } = data

  return (
    <>

      <Title>Latest posts</Title>

      {edges.map(({ node }) => {
        const { frontmatter, fields, excerpt } = node
        // eslint-disable-next-line no-unused-vars
        const { title, date, description, author } = frontmatter
        const { slug } = fields

        return (
          <Article key={slug}>
            <header>
              <Subtitle to={slug}>{title}</Subtitle>
              <small>{date}</small>
            </header>
            <section
              dangerouslySetInnerHTML={{
                __html: excerpt
              }}
            />
            <Button
              mt={3}
              rightIcon="more"
              to={slug}
              as={Link}
            >
              read more
            </Button>
          </Article>
        )
      })}
    </>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired
}
