import { graphql } from 'gatsby'
import BlogPage from 'views/Blog/BlogPage'
export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(
      filter: { fields: { slug: { regex: "/^/posts/" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
            author
          }
        }
      }
    }
  }
`
